import React from "react"
import { graphql } from "gatsby"

// s4n
import FragmentBlogIndex from '../../model/fragments/allNodeBlog/blog-index'
import { injectIntl } from "gatsby-plugin-intl"
import BlogIndex from './../../components/s4n/Blog'



const ComponentStateless = (props) => {
  const { 
    // data: { 
    //   /**
    //    * @todo: fix wrong context variable "language" in EN context it has "pl" value
    //    * We create different GraphQL queries for each language, 
    //    * because when we have the same URL (page name, ex: "blog", 
    //    * gatsby-plugin-intl passes as context variable -> "language" from gatsby-node.js wrong language value!!!
    //    */
    //   // index, 
    //   indexPL, 
    //   indexEN,
    // }, 
    data,
    pageContext, intl 
  } = props

  // console.log(pageContext)
  // console.log(index.edges)

  // let aDataCollectionTranslated = null;
  // if (`pl` === intl.locale) {
  //   aDataCollectionTranslated = indexPL.edges;
  // }
  // if (`en` === intl.locale) {
  //   aDataCollectionTranslated = indexEN.edges;
  // }
  let aDataCollectionTranslated = data?.[`index${intl.locale.toUpperCase()}`].edges;

  const propsData = {
    pageContext,
    // aDataCollectionTranslated: index.edges
    aDataCollectionTranslated: aDataCollectionTranslated,
  };

  return <BlogIndex propsData={propsData} />
}

export default injectIntl(ComponentStateless)

export const query = graphql`
    query (
        $skip: Int!, 
        $limit: Int!,
        $langcode: String!,
        $field_type: String!,
        $tag: String!
    ) {

        index: allNodeBlog (
            filter: {
              # this node is available in graphql database since it is published, we need to filter it out here
              title: {
                  ne: "SOFT4NET_DO_NOT_DELETE"
              }
              langcode: {
                eq: $langcode # @todo: bad value!!!
              }
              field_type: {
                eq: $field_type
              }
              relationships: {
                field_tags: {
									elemMatch: {
                    name: {
                      eq: $tag
                    }
                  }
                }
              }
            }
            sort: {
              fields: [created]
              order: DESC
            }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    ...FragmentBlogIndex
                }
            }
        }

        indexPL: allNodeBlog (
            filter: {
              # this node is available in graphql database since it is published, we need to filter it out here
              title: {
                  ne: "SOFT4NET_DO_NOT_DELETE"
              }
              langcode: {
                eq: "pl"
              }
              field_type: {
                eq: $field_type
              }
              relationships: {
                field_tags: {
									elemMatch: {
                    name: {
                      eq: $tag
                    }
                  }
                }
              }
            }
            sort: {
              fields: [created]
              order: DESC
            }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    ...FragmentBlogIndex
                }
            }
        }

        indexEN: allNodeBlog (
            filter: {
              # this node is available in graphql database since it is published, we need to filter it out here
              title: {
                  ne: "SOFT4NET_DO_NOT_DELETE"
              }
              langcode: {
                eq: "en"
              }
              field_type: {
                eq: $field_type
              }
              relationships: {
                field_tags: {
									elemMatch: {
                    name: {
                      eq: $tag
                    }
                  }
                }
              }
            }
            sort: {
              fields: [created]
              order: DESC
            }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    ...FragmentBlogIndex
                }
            }
        }

        indexDE: allNodeBlog (
          filter: {
            # this node is available in graphql database since it is published, we need to filter it out here
            title: {
                ne: "SOFT4NET_DO_NOT_DELETE"
            }
            langcode: {
              eq: "de"
            }
            field_type: {
              eq: $field_type
            }
            relationships: {
              field_tags: {
                elemMatch: {
                  name: {
                    eq: $tag
                  }
                }
              }
            }
          }
          sort: {
            fields: [created]
            order: DESC
          }
          limit: $limit
          skip: $skip
      ) {
          edges {
              node {
                  ...FragmentBlogIndex
              }
          }
      }

      indexES: allNodeBlog (
        filter: {
          # this node is available in graphql database since it is published, we need to filter it out here
          title: {
              ne: "SOFT4NET_DO_NOT_DELETE"
          }
          langcode: {
            eq: "es"
          }
          field_type: {
            eq: $field_type
          }
          relationships: {
            field_tags: {
              elemMatch: {
                name: {
                  eq: $tag
                }
              }
            }
          }
        }
        sort: {
          fields: [created]
          order: DESC
        }
        limit: $limit
        skip: $skip
    ) {
        edges {
            node {
                ...FragmentBlogIndex
            }
        }
    }

    }
`